import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./layout/publicLayout/Footer";
import Header from "./layout/publicLayout/Header";

// Public Pages
import Home from "./pages/home";
import About from "./pages/about";
import Driver from "./pages/driver";
import Riders from "./pages/riders";
import Business from "./pages/business";
import PhoneLogin from "./pages/PhoneLogin";

import PublicLayout from "./layout/publicLayout";
import PassengerLayout from "./layout/passengerLayout";

// Passenger Pages
import RideNow from "./pages/passengerPages/RideNow";
import Rides from "./pages/passengerPages/Rides";
import RideDetails from "./pages/passengerPages/RideDetails";

import { Toaster } from "react-hot-toast";
import VerifyOtp from "./pages/PhoneLogin/verifyOtp";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  asyncGetUserLocation,
  hasLocationPermission,
} from "./redux/slices/locationSlice";
import PassengerDetails from "./pages/PhoneLogin/passengerDetails";
// import CurrentRide from "./pages/passengerPages/CurrentRide";
import Wallet from "./pages/passengerPages/Wallet";
import Profile from "./pages/profile";
// import DeviceUUID from "device-uuid";

// import "./assets/css/all.min.css";
// import "./assets/css/bootstrap-icons.css";
// import "./assets/css/bootstrap-timepicker.min.css";
// import "./assets/css/datepicker.min.css";
// import "./assets/css/font-awesome.css";
// import "./assets/css/font-awesome.min.css";
// import "./assets/css/main.css";
// import "./assets/css/main.css.map";
// import "./assets/css/main.min.css";
// import "./assets/css/select2.min.css";
// import "./assets/css/simple-line-icons.css";
// import "./assets/css/custom-css.css";
// import "./assets/css/login.css";
import "./assets/css/style.css";

// import "./assets/css/all.min.css";
import { asyncGetCompanySettings } from "./redux/slices/settingSlice";
import PrivacyPolicy from "./pages/privacyPolicy";
import ContactUs from "./pages/contactUs";
import TermsService from "./pages/TermsService";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import AddCard from "./pages/test";
import CustomRoute from "./components/CustomRoute";

function App() {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(asyncGetCompanySettings());
  // }, []);

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          console.log("devices=>", devices);
          devices.forEach((device) => {
            console.log(
              `${device.kind}: ${device.label} id = ${device.deviceId}`
            );
          });
        })
        .catch((err) => {
          console.log(`${err.name}: ${err.message}`);
        });
    }
  }, [navigator]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        handleSuccess,
        handleError
        // dispatch(asyncGetUserLocation)
      );
      // console.log(coords);
    } else {
      console.log("Geolocation is not available");
      dispatch(hasLocationPermission(false));
    }
  }, [navigator.geolocation]);

  const handleSuccess = (position) => {
    dispatch(hasLocationPermission(true));

    console.log(position);
    const { latitude, longitude } = position.coords;
    dispatch(asyncGetUserLocation({ latitude, longitude }));
  };

  const handleError = (err) => {
    console.log(err);
    dispatch(hasLocationPermission(false));
  };

  const [uuid, setUuid] = useState("");

  // useEffect(() => {
  //   const uuid = new DeviceUUID().get();
  //   setUuid(uuid);
  // }, []);

  // console.log("uuid", uuid);

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const deviceId = result.visitorId;
      console.log("deviceId", deviceId);
    };
    fetchDeviceInfo();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Toaster />
        <ScrollToTop />
        <Routes>
          <Route
            path=""
            element={
              <PublicLayout>
                <Outlet />
              </PublicLayout>
            }
          >
            <Route path="/" element={<CustomRoute element={Home} />} />{" "}
            <Route path="/about-us" element={<CustomRoute element={About} />} />
            <Route
              path="/contact-us"
              element={<CustomRoute element={ContactUs} />}
            />
            <Route
              path="/privacy-policy"
              element={<CustomRoute element={PrivacyPolicy} />}
            />
            <Route
              path="/services"
              element={<CustomRoute element={TermsService} />}
            />
            <Route path="/drivers" element={<CustomRoute element={Driver} />} />
            <Route path="/riders" element={<CustomRoute element={Riders} />} />
            <Route path="/driver" element={<CustomRoute element={Riders} />} />
            <Route
              path="/business"
              element={<CustomRoute element={Business} />}
            />
            {/* <Route path="/about-us" element={<About />} /> */}
            {/* <Route path="/contact-us" element={<ContactUs />} /> */}
            {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
            {/* <Route path="/services" element={<TermsService />} /> */}
            {/* <Route path="/drivers" element={<Driver />} /> */}
            {/* <Route path="/riders" element={<Riders />} /> */}
            {/* <Route path="/driver" element={<Riders />} /> */}
            {/* <Route path="/business" element={<Business />} /> */}
            <Route path="/verify-otp" element={<VerifyOtp />} />
            <Route path="/passenger-details" element={<PassengerDetails />} />
          </Route>

          <Route path="/login" element={<PhoneLogin />} />
          <Route path="/test" element={<AddCard />} />

          <Route
            path="/"
            element={
              <PassengerLayout>
                <Outlet />
              </PassengerLayout>
            }
          >
            {/* <Route path="" element={<Dashboard />} /> */}
            {/* <Route path="profile" element={<Profile />} /> */}
            {/* <Route path="profile/edit" element={<EditProfile />} /> */}
            {/* <Route path="promotion" element={<Promotion />} /> */}
            <Route path="rides" element={<Rides />} />
            <Route path="rides/:id" element={<RideDetails />} />
            <Route path="ride-now" element={<RideNow />} />
            {/* <Route path="current-ride" element={<CurrentRide  />} /> */}
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/profile" element={<Profile />} />

            {/* <Route path="wallet" element={<Wallet />} /> */}
            {/* <Route path="payment" element={<Payment />} /> */}
            {/* <Route path="scheduled-rides" element={<ScheduledRides />} /> */}
          </Route>

          {/* <Route path="/book-a-ride" element={<BookARide />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/login" element={<PhoneLogin />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/passenger-details" element={<PassengerDetails />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
