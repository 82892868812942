import React from "react";
import images from "../../assets/new-images/image.png";
import icons from "../../assets/new-images/icon.png";
import icons1 from "../../assets/new-images/icon-1.png";
import icons2 from "../../assets/new-images/icon-2.png";
import icons3 from "../../assets/new-images/icon-3.png";
import serv1 from "../../assets/new-images/serv-1.png";
import serv2 from "../../assets/new-images/serv-2.png";
import serv3 from "../../assets/new-images/serv-3.png";
import sericon from "../../assets/new-images/sericon.png";
import sericon2 from "../../assets/new-images/sericon-2.png";
import sericon3 from "../../assets/new-images/sericon-3.png";
import sericon4 from "../../assets/new-images/sericon-4.png";
import downicon from "../../assets/new-images/downicon-1.png";
import downicon2 from "../../assets/new-images/downicon-2.png";
import downicon3 from "../../assets/new-images/downicon-3.png";
import downicon4 from "../../assets/new-images/downicon-4.png";
import googleapp from "../../assets/new-images/googleapp.png";
import appstoe from "../../assets/new-images/appstoe.png";
import doenloadapp from "../../assets/new-images/doenloadapp.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import images from "../../assets/new-images/icon-3.png"

function Home() {
  return (
    <>
      <div className="slider-section">
        <Helmet>
          <title>Reliable Car Service in Bloomington, MN | Cabtify</title>
          <meta
            name="description"
            content="Book your ride with Cabtify for safe, comfortable transportation in Bloomington, MN. Airport transfers, corporate rides & special events. 24/7 availability."
          />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-7 col-sm-12">
              <div className="headerslider">
                <h4>Welcome to</h4>
                <h1>Cabtify</h1>
                <p>Your Go-To Car Service in Bloomington, MN</p>
                <Link to="/ride-now" className="btn btn-secondary rounded-1">
                  Book a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="abt-section">
        <div className="container">
          <div className="row align-items-center justify-content-around">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="ratio ratio-4x3 shadow-sm rounded-5">
                <img src={images} alt="About image" />
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="text-center abttext">
                <h2>About Us</h2>
                <p>
                  Cabtify is committed to providing top-quality, dependable
                  transportation across Bloomington, MN, and surrounding areas.
                  Whether you are heading to the airport, attending a corporate
                  event, or celebrating a special occasion, Cabtify delivers
                  reliable service with a focus on comfort, safety, and
                  convenience. We believe in making every ride seamless and
                  enjoyable, tailored to meet the unique needs of each client.
                </p>
                <Link
                  to="/about-us/"
                  className="btn btn-secondary rounded-1 px-4"
                >
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ser-section">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 mb-3">
              <div className="text-center">
                <h2>Our Services</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card rounded-5 h-100">
                <div className="card-body">
                  <div className="ratio ratio-16x9">
                    <img src={serv1} alt="Service icon 1" />
                  </div>
                  <div className="iconimg mb-3">
                    <img src={sericon} alt="Service icon 2" />
                  </div>
                  <div className="sercardtext">
                    <h5 className="mb-2">Airport Transfers</h5>
                    <p>
                      Start your travels on the right note with our punctual and
                      comfortable airport transfer services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card rounded-5 h-100">
                <div className="card-body">
                  <div className="ratio ratio-16x9">
                    <img src={serv2} alt="" />
                  </div>
                  <div className="iconimg mb-3">
                    <img src={sericon2} alt="" />
                  </div>
                  <div className="sercardtext">
                    <h5 className="mb-2">Corporate Transportation</h5>
                    <p>
                      Our executive car service is ideal for professionals
                      needing efficient and dependable transportation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div className="card rounded-5 h-100">
                <div className="card-body">
                  <div className="ratio ratio-16x9">
                    <img src={serv3} alt="Service icon 1" />
                  </div>
                  <div className="iconimg mb-3">
                    <img src={sericon3} alt="Service icon 2" />
                  </div>
                  <div className="sercardtext">
                    <h5 className="mb-2">Special Events &amp; Occasions</h5>
                    <p>
                      From weddings to anniversaries and proms, let Cabtify make
                      your celebrations unforgettable.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="text-center">
                <a
                  href="/services"
                  className="btn btn-secondary rounded-1 px-5"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="abt-section">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-md-12 mb-3">
              <div className="text-center">
                <h2>Why Choose Cabtify?</h2>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
              <div className="align-items-center d-flex flex-column flex-md-row gap-3 ">
                <div className="icon-img">
                  <img src={icons} alt="Service icon" />
                </div>
                <div className="text-center text-md-start">
                  <h4>On-Time Service</h4>
                  <p>
                    We value your time and are dedicated to prompt, reliable
                    service.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 mb-3">
              <div className="align-items-center d-flex flex-column flex-md-row gap-3 ">
                <div className="iconimgs">
                  <img src={icons1} alt="Professional image" />
                </div>
                <div className="text-center text-md-start">
                  <h4>Professional Drivers</h4>
                  <p>
                    Our drivers are experienced, friendly, and focused on
                    delivering the best service.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="align-items-center d-flex flex-column flex-md-row gap-3 ">
                <div className="icon-img">
                  <img src={icons2} alt="Modern image" />
                </div>
                <div className="text-center text-md-start">
                  <h4>Modern Fleet</h4>
                  <p>
                    Our fleet is equipped with modern, comfortable vehicles
                    suited for any occasion.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="align-items-center d-flex flex-column flex-md-row gap-3 ">
                <div className="iconimgs">
                  <img src={icons3} alt="Easy Booking image" />
                </div>
                <div className="text-center text-md-start">
                  <h4>Easy Booking</h4>
                  <p>
                    Book a ride quickly through our online system or by phone
                    for a hassle-free experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ser-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
              <div className="text-center">
                <h2>Download Our Mobile App</h2>
                <p>
                  Enjoy the full Cabtify experience right from your mobile
                  device! Our app offers convenient features to book, track, and
                  manage your rides anytime, anywhere.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <div className="card text-center">
                <img
                  src={downicon}
                  className="card-img-toprounded-0 w-25"
                  alt="Down icon"
                />
                <div className="card-body">
                  <h5 className="mb-2">Instant Booking</h5>
                  <p>Schedule your ride in just a few taps.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <div className="card text-center">
                <img
                  src={downicon2}
                  className="card-img-toprounded-0 w-25"
                  alt="Real-Time icon"
                />
                <div className="card-body">
                  <h5 className="mb-2">Real-Time Tracking</h5>
                  <p>
                    Stay updated with live tracking of your driver&apos;s
                    location.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <div className="card text-center">
                <img
                  src={downicon3}
                  className="card-img-toprounded-0 w-25"
                  alt="Secure Payment Icon"
                />
                <div className="card-body">
                  <h5 className="mb-2">Secure Payment</h5>
                  <p>
                    Enjoy secure, in-app payment options for added convenience.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
              <div className="card text-center">
                <img
                  src={downicon4}
                  className="card-img-toprounded-0 w-25"
                  alt="Access image"
                />
                <div className="card-body">
                  <h5 className="mb-2">24/7 Access</h5>
                  <p>Available anytime you need a ride, day or night.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-app-section">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div>
                <h3>Download the Cabtify App Today!</h3>
                <p>Available on the App Store and Google Play.</p>
                <div className="d-flex gap-3">
                  <div className="">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.iconictek.cabtify.request.ride&pcampaignid=web_share"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={googleapp}
                        className="w-75"
                        alt="Play store image"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://apps.apple.com/pk/app/cabtify-request-a-ride/id6503247684"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={appstoe}
                        className="w-75"
                        alt="Apple Store image "
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="downloadapp">
                <img src={doenloadapp} alt="Download app" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cont-section mb-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="con-text">
                <h2>Contact Us</h2>
                <p>
                  Book your next ride with Cabtify, and experience
                  transportation that puts your needs first.
                </p>
                <Link to="/contact-us" className="btn btn-light rounded-1 px-5">
                  Contact Us Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
