import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const CustomRoute = ({ element: Element, ...rest }) => {
  const location = useLocation();
  const { pathname } = location;

  //   console.log("pathname=>", pathname, !pathname.endsWith("/"), pathname.length);
  //   if (pathname.length == 1) {
  //     return <Navigate to={`/`} replace />;
  //   }
  if (!pathname.endsWith("/")) {
    return <Navigate to={`${pathname}/`} replace />;
  }

  return <Element {...rest} />;
};

export default CustomRoute;
