import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import {
  NotificationAPI,
  useGetNotificationAPIQuery,
  useUpdateNotificationMutation,
} from "../../redux/services/notificationAPI";
import { calculateMessageTime } from "../../utils/time";
import { BiUser } from "react-icons/bi";
import { BUCKET_PUBLIC_URL } from "../../constants";
import socket from "../../services/socket";
import { MdSettings } from "react-icons/md";
import DotsLoader from "../../components/loading/DotsLoader";

function Header() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const logoutHandler = () => {
    dispatch(logout());
  };

  const [showA, setShowA] = useState(false);

  const toggleShowA = () => setShowA(!showA);

  const [page, setPage] = useState(1);
  const {
    data: notifications,
    isLoading,
    error,
    isFetching,
  } = useGetNotificationAPIQuery({ page });

  console.log(notifications, "notificationIsRead");
  const [updateNotification, { data }] = useUpdateNotificationMutation();

  const notificationIsRead = notifications?.results?.filter(
    (items) => items.isRead !== true
  );

  const readNotificationHandler = async (id, isRead) => {
    await updateNotification(id)
      .unwrap()
      .then((res) => {
        console.log("chal rahi hai ");
        dispatch(
          NotificationAPI.util.updateQueryData(
            "getNotificationAPI",
            { page: 1, pageSize: 10 },
            (draft) => {
              console.log(draft, "getNotificationAPI");
              draft.results = draft.results.map((x, i) =>
                x._id == id ? { ...x, isRead: true } : x
              );
            }
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notificationContainerRef = useRef(null);

  function handleScroll() {
    const container = notificationContainerRef.current;
    // console.log(container, "container");
    if (!container) return;

    const scrolled =
      parseInt(container.scrollHeight - container.scrollTop) ===
      container.clientHeight;
    console.log(scrolled, "scrolled");

    if (scrolled) {
      // Logic to handle reaching the bottom of the container
      if (!isFetching) {
        console.log("Fetching more data...");
        if (!notifications || notifications.totalPages > page) {
          setPage(page + 1);
        }
      }
    }
  }

  useEffect(() => {
    const handleNewNotification = (data) => {
      console.log("new-notification", data);
      // Assuming 'data' is the new notification object you want to add to the list
      dispatch(
        NotificationAPI.util.updateQueryData(
          "getNotificationAPI",
          { page: 1, pageSize: 10 },
          (draft) => {
            draft.results.unshift(data);
          }
        )
      );
      const audio = new Audio("/notification/notification.wav");
      audio.play();
    };

    socket.on("new-notification", handleNewNotification);

    return () => {
      socket.off("new-notification");
    };
  }, []);

  return (
    <div className="paraxdo-header-section sticky-top bg-white">
      <div className="container">
        <div className="row">
          <div className="paraxdo-header-img">
            <nav className="navbar sticky-top navbar-expand-lg ">
              <div className="container-fluid">
                <NavLink className="navbar-brand" to="/ride-now">
                  <img
                    src="assets/images/logo-black.png"
                    className="logo-black"
                    height={70}
                    alt="Navbar logo"
                  />
                </NavLink>{" "}
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item text-black">
                      <NavLink
                        className="nav-link insideOfApp"
                        id="home-col text-black"
                        to="/ride-now"
                      >
                        Ride Now
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link text-black" to="/rides">
                        My Rides
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link text-black" to="/wallet">
                        Wallet
                      </NavLink>
                    </li>

                    <li className="nav-item dropdown d-md-none">
                      <a
                        className="nav-link dropdown-toggle text-black"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {auth.userInfo.firstName} {auth.userInfo.lastName}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            Profile
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="dropdown-item"
                            to=""
                            onClick={logoutHandler}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>{" "}
                <div className="nav-right-content user-dropdown">
                  <ul className="navbar-nav align-items-center">
                    <li className="nav-item nav-icon dropdown">
                      <a
                        href="#"
                        onClick={toggleShowA}
                        className="search-toggle  notification_list"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ position: "relative" }}
                      >
                        <svg
                          className="svg-icon"
                          style={{ color: "#00A699" }}
                          id="mm-bell-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"
                            style={{
                              strokeDasharray: "57, 77",
                              strokeDashoffset: 0,
                            }}
                          />
                          <path
                            d="M13.73 21a2 2 0 0 1-3.46 0"
                            style={{
                              strokeDasharray: "5, 25",
                              strokeDashoffset: 0,
                            }}
                          />
                        </svg>
                      </a>

                      {notifications && notificationIsRead.length !== 0 ? (
                        <span
                          className=" text-white px-2 py-1 rounded-pill"
                          style={{ fontSize: 12, backgroundColor: "#42AD57" }}
                        >
                          {notifications && notificationIsRead.length}
                        </span>
                      ) : (
                        <span
                          class="dots"
                          style={{ position: "absolute", top: -3, right: -5 }}
                        ></span>
                      )}

                      <Toast
                        show={showA}
                        onClose={toggleShowA}
                        style={{
                          position: "absolute",
                          top: 30,
                          right: 0,
                          maxWidth: 350,
                        }}
                      >
                        <Toast.Header>
                          <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt="notification"
                          />
                          <strong className="me-auto">Notification</strong>
                        </Toast.Header>
                        <Toast.Body
                          className="bg-white px-0 py-0"
                          ref={notificationContainerRef}
                          onScroll={handleScroll}
                          style={{
                            maxHeight: "300px",
                            height: "100%",
                            overflowY: "scroll",
                          }}
                        >
                          {notifications &&
                          notifications?.results.length === 0 ? (
                            <p className="text-center"> No notifications</p>
                          ) : (
                            <>
                              {notifications?.results?.map(
                                (notification, index) => (
                                  <li
                                    className="notification-message pt-2"
                                    key={index}
                                    onClick={() =>
                                      readNotificationHandler(
                                        notification._id,
                                        notification.isRead
                                      )
                                    }
                                    style={{
                                      borderBottom:
                                        index !==
                                        notifications?.results.length - 1
                                          ? "1px solid #888888"
                                          : "",
                                    }}
                                  >
                                    <Link
                                      href={notification?.redirectTo}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="media d-flex  gap-2 px-2">
                                        <div className="avatar avatar-sm">
                                          {notification?.sender
                                            ?.profileImageUrl ? (
                                            <img
                                              src={`${BUCKET_PUBLIC_URL}/${notification?.sender?.profileImageUrl}`}
                                              width={30}
                                              height={30}
                                              alt="Profile image"
                                              className="rounded-circle"
                                            />
                                          ) : (
                                            <>
                                              <MdSettings
                                                className="text-primary"
                                                style={{
                                                  width: 30,
                                                  height: 30,
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                        <div className="media-body w-75">
                                          <p
                                            className="noti-details text-truncate text-black m-0 py-1"
                                            style={{
                                              fontSize: 14,
                                              fontWeight: "600",
                                              textDecoration: "none",
                                            }}
                                          >
                                            <span className="noti-title ">
                                              {notification?.sender?.firstName}{" "}
                                              {notification?.sender?.lastName}{" "}
                                            </span>
                                            <span
                                              style={{
                                                color: "gray",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {notification?.title}
                                            </span>
                                          </p>
                                          <div className="d-flex justify-content-between align-items-end py-2">
                                            <p
                                              className="noti-time notification-time m-0 pt-1 "
                                              style={{
                                                color: "gray",
                                                fontSize: 13,
                                              }}
                                            >
                                              {calculateMessageTime(
                                                notification.createdAt
                                              )}
                                            </p>

                                            <p
                                              className="badge badge-primary m-0 "
                                              style={{
                                                backgroundColor: "#42AD57",
                                              }}
                                            >
                                              {notification.isRead ? "" : "New"}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              )}

                              {isFetching && (
                                <div className="d-flex justify-content-center bg-white py-3 px-4 ">
                                  <DotsLoader dark />
                                </div>
                              )}
                            </>
                          )}
                        </Toast.Body>
                      </Toast>
                    </li>

                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle text-black"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {auth.userInfo.firstName} {auth.userInfo.lastName}
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            Profile
                          </Link>
                        </li>
                        {/* <li>
                          <Link className="dropdown-item" to="">
                            Change Password
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            className="dropdown-item"
                            to=""
                            onClick={logoutHandler}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
